import { useCallback, useEffect, useState, useReducer } from 'react'
import moment from 'moment'
import { Image } from 'react-bootstrap'
import { Box, Typography } from '@mui/material'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** CONSTANTS */
import { colors, ThemeIcon } from 'constants/theme'
import { scale } from 'constants/scale'
import { checkPackage } from 'utils/app.utils'
import { PACKAGE_ADDONS } from 'constants/package'
import { Icons, icons } from 'constants/images'

/** ICON */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserTimes, faTimes, faCheck, faUser } from '@fortawesome/free-solid-svg-icons'

/** STYLE */
import { styled } from '@mui/material/styles'
import { useSelector } from 'react-redux'
import { selectMe } from 'app/slice/user.slice'

type AttibuildCard = {
  height: string | number,
  left: number,
  colorBackground?: string,
  translateY: string | number,
  translateX: string | number,
  statusAppointment: string,
  statusColor: string,
  key: number
  width: number
  allRow?: boolean
}
const CardAppointment = styled('div')<AttibuildCard>(({ theme, height, left, colorBackground, translateY, translateX, statusAppointment, statusColor, key, width, allRow }) => ({
  position: 'absolute',
  top: `calc(${1.5}rem + ${0.125}rem)`,
  left: `calc(0 +  ${left}px)`,
  // right: '.125rem',
  display: 'flex',
  width: width,
  flexDirection: 'column',
  justifyContent: Number(height) > 30 ? 'flex-start' : 'center',
  background: colorBackground || colors.lightGray,
  border: `1px solid ${colors.lightGray}`,
  borderRadius: 4,
  padding: Number(height) > 30 ? '.2rem 2.25rem' : '0 .2rem 0 2rem',
  paddingLeft: '32px',
  paddingRight: '.5rem',
  fontSize: 16,
  color: colors.textPrimary,
  zIndex: 3,
  height: height,
  transform: `translateY(${translateY}px) translateX(${translateX}px)`,
  cursor: 'pointer',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: 24,
    height: '100%',
    backgroundColor: statusColor,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4
  },
  '& .icon': {
    display: 'flex',
    justifyContent: 'flex-start',
    position: 'absolute',
    paddingTop: Number(height) > 30 ? '4px' : '0px',
    // top: '50%',
    left: 5,
    // transform: 'translateY(-50%)',
    '& svg': {
      width: 15,
      height: 15
    },
    '& img': {
      width: 15,
      height: 15
    },
    '& path': {
      fill: colors.white
    },
  },
  '&:hover': {
    // boxShadow: statusAppointment !== 'DONE' ? `0px 3px 6px ${colors.black08}` : 'none',
    boxShadow: `0px 3px 6px ${colors.black08}`
  },
  'p': {
    fontSize: allRow ? '12px !important' : '16px !important'
  }
}))

const Tag = styled('div')(({ theme }) => ({
  display: 'inline-block',
  marginLeft: '.25rem',
  padding: '0 .5rem',
  fontSize: 12,
  color: colors.white,
  backgroundColor: colors.themeThirdColor,
  borderRadius: 3
}))

const BoxTextCardAppointment = styled(Box)<{ heigth: boolean }>(({ theme, heigth }) => ({
  '.text-card-appointment': {
    fontSize: heigth ? '12px !important' : '1rem',
    lineHeight: heigth ? '1 !important' : '1.3'
  }
}))

export const renderIconStatusAppointment = (statusAppointment: any, iconOnly?: boolean) => {

  let renderIcon: any = <FontAwesomeIcon style={{ color: colors.white }} icon={faUser} />
  if (statusAppointment === 'CONFIRM') renderIcon = <FontAwesomeIcon style={{ color: colors.white }} icon={faCheck} />
  if (statusAppointment === 'NOT_YET') renderIcon = <FontAwesomeIcon style={{ color: colors.white }} icon={faUserTimes} />
  if (statusAppointment === 'CANCEL') renderIcon = <FontAwesomeIcon style={{ color: colors.white }} icon={faTimes} />
  if (statusAppointment === 'CANCEL_APP') renderIcon = <Image style={{ height: '15px' }} src={icons.appointment.cancel_app} />
  if (statusAppointment === 'CAN_NOT_CONTACT') renderIcon = <Image src={icons.missedCall} />
  if (statusAppointment === 'ARRIVED') renderIcon = <Image src={icons.userCheckTrue} />
  // 
  if (statusAppointment === 'PAYMENT') renderIcon = <Image src={icons.iconPendingPayment} />
  if (statusAppointment === 'PENDING_PAYMENT') renderIcon = <Image src={icons.iconWaitPayment} />
  if (statusAppointment === 'PENDING_SERVICE') renderIcon = <Image src={icons.iconCounterCheckIn} />
  if (statusAppointment === 'SERVICE') renderIcon = <Image src={icons.iconService} />
  // 
  if (statusAppointment === 'PUT_OFF') renderIcon = <Image src={icons.iconRight} />
  if (statusAppointment === 'DONE') renderIcon = <Image src={ThemeIcon.DoneTreatment} />
  if (iconOnly) return renderIcon
  return <span className={'icon'}>{renderIcon}</span>
}

export const getColorStatusAppointment = (statusAppointment: any) => {
  let color: any = colors.lightGray
  if (statusAppointment === 'CONFIRM') color = colors.appointmentStatus.statusEnter
  if (statusAppointment === 'NOT_YET') color = colors.appointmentStatus.statusUnArrived
  if (statusAppointment === 'CANCEL') color = colors.appointmentStatus.statusCancel
  if (statusAppointment === 'CANCEL_APP') color = colors.appointmentStatus.statusCancel
  if (statusAppointment === 'CAN_NOT_CONTACT') color = colors.appointmentStatus.statusUnConnect
  if (statusAppointment === 'ARRIVED') color = colors.appointmentStatus.statusArrived
  // 
  if (statusAppointment === 'PAYMENT') color = colors.appointmentStatus.statusWaitPayment
  if (statusAppointment === 'PENDING_PAYMENT') color = colors.appointmentStatus.statusPayment
  if (statusAppointment === 'PENDING_SERVICE') color = colors.appointmentStatus.statusCounterCheckIn
  if (statusAppointment === 'SERVICE') color = colors.appointmentStatus.statusService
  //
  if (statusAppointment === 'PUT_OFF') color = colors.appointmentStatus.statusChange
  if (statusAppointment === 'DONE') color = colors.appointmentStatus.statusDone
  if (statusAppointment === 'NORMAL') color = colors.appointmentStatus.statusNormal
  return color
}

type CardScheduleProps = {
  key?: any
  appointmentId: number
  status?: string
  branchCnNumber?: string
  serviceText?: string
  colorBackground?: string
  patientFullName?: string
  rangeTimeText?: string
  start?: any
  end?: any
  hasLab?: boolean
  hasXray?: boolean
  timeStart: string
  width?: number
  translateY?: number
  translateX?: number
  countThis?: number
  countThisTime?: number
  onClick?: (event: any) => void
  onViewDetail?: (val: number) => void
  classes?: string
  specialInfo?: string
  type?: string
  phone?: string
  allRow?: boolean
}
function useForceUpdate() {
  const [, setToggle] = useState(false);
  return () => setToggle(toggle => !toggle);
}
export default function Card(props: CardScheduleProps) {
  const user = useSelector(selectMe)
  const [statusColor, setStatusColor] = useState(colors.lightGray)

  const { t } = useTranslation()
  const [height, setHeight] = useState(0)
  const [translateY, setTranslateY] = useState(0)
  const [translateX, setTranslateX] = useState(0)
  const [minute, setMinute] = useState<number>(0)
  const unit = scale.rangeOfMinute

  const handleNowPoint = useCallback(() => {
    const start = moment(props.start, 'HH:mm')
    const end = moment(props.end, 'HH:mm')
    const defaultTime = moment(props.timeStart, 'HH:mm').toDate()
    const diffHeight = moment.duration(end.diff(start)).asMinutes()

    setMinute(diffHeight)
    const diffTransform = moment.duration(start.diff(defaultTime)).asMinutes()
    setHeight(diffHeight * unit)


    setTranslateY(diffTransform * unit)
    setTranslateX(props?.translateX || 0)

    getColorAppointmentStatus()
  }, [props.start, props.end])

  useEffect(() => {
    handleNowPoint()
  }, [handleNowPoint])
  const getColorAppointmentStatus = () => {
    let color: any = colors.lightGray
    if (props.status === 'CONFIRM') color = colors.appointmentStatus.statusEnter
    if (props.status === 'NOT_YET') color = colors.appointmentStatus.statusUnArrived
    if (props.status === 'CANCEL') color = colors.appointmentStatus.statusCancel
    if (props.status === 'CAN_NOT_CONTACT') color = colors.appointmentStatus.statusUnConnect
    if (props.status === 'ARRIVED') color = colors.appointmentStatus.statusArrived
    // 
    if (props.status === 'PENDING_PAYMENT') color = colors.appointmentStatus.statusPayment
    if (props.status === 'PAYMENT') color = colors.appointmentStatus.statusWaitPayment
    if (props.status === 'PENDING_SERVICE') color = colors.appointmentStatus.statusCounterCheckIn
    if (props.status === 'SERVICE') color = colors.appointmentStatus.statusService
    //
    if (props.status === 'PUT_OFF') color = colors.appointmentStatus.statusChange
    if (props.status === 'DONE') color = colors.appointmentStatus.statusDone
    if (props.status === 'NORMAL') color = colors.appointmentStatus.statusNormal
    return setStatusColor(color)
  }

  useEffect(() => {
    getColorAppointmentStatus()
  }, [props.status])

  const renderPhone = () => {
    if (user.hasShowPhoneNumber === 'PERMISS' && props?.phone) {
      return ` ${props?.phone} | `
    } else {
      return ''
    }
  }

  return (<div key={props?.key || 1}>
    <CardAppointment
      data-value={props.appointmentId}
      width={props?.width || 435}
      key={props?.key || 1}
      onClick={() => props?.onViewDetail && props?.onViewDetail(props.appointmentId)}
      height={height}
      colorBackground={props.colorBackground}
      statusAppointment={props.status || ''}
      statusColor={statusColor}
      translateY={translateY}
      translateX={translateX}
      left={translateX}
      className={`${props.classes || ''} card-appointment`}
      allRow={props?.allRow}
    >
      {renderIconStatusAppointment(props.status)}
      <Box sx={{ overflow: 'hidden' }}>
        {minute >= 30 ? (
          <>
            <BoxTextCardAppointment heigth={height < 40}>
              <Typography
                className={'text-card-appointment m-0 overflow-hidden'}
                sx={{ whiteSpace: 'nowrap' }}
              >
                {`${props.branchCnNumber}  | ${props.patientFullName || ''}`}
              </Typography>
              <Typography className={'text-card-appointment m-0 text-ellipsis'}>
                {`${renderPhone()}${props.rangeTimeText} ${t('TIME_UNIT')} `}
              </Typography>
            </BoxTextCardAppointment>

            <Box className='d-flex align-items-start'>
              <Box className='d-flex align-items-center' sx={{ flex: 'none' }}>
                <Box className='d-flex align-items-center justify-content-center' sx={{ flex: 'none', width: '20px', svg: { width: '16px' } }}>
                  <ThemeIcon.Treatment stroke={getColorStatusAppointment(props.status) === colors.lightGray ? colors.disabledGray : getColorStatusAppointment(props.status)} />
                </Box>
                <Typography className={`ml-1 text-ellipsis`} sx={{ fontWeight: 500, flex: 1, height: '24px' }}>
                  {/* {t('APPOINTMENT.FORM.TREATMENT_TITLE')} : */}
                </Typography>
              </Box>
              <Typography className={`mx-1 text-ellipsis`}>{props.serviceText}</Typography>
              <Typography className={'m-0'} sx={{ flex: 'none' }}>
                {props.hasLab && checkPackage(PACKAGE_ADDONS.ADDON_LAB) ? <Tag>LAB</Tag> : <></>} {props.hasXray && checkPackage(PACKAGE_ADDONS.ADDON_X_RAY) ? <Tag>X-Ray</Tag> : <></>}
              </Typography>
            </Box>
            <Box className='d-flex align-items-start'>
              <Box className='d-flex align-items-center' sx={{ flex: 'none' }}>
                <Box className='d-flex align-items-center justify-content-center' sx={{ flex: 'none', width: '20px', svg: { width: '18px', height: '18px' } }}>
                  <Icons.IconStar stroke={getColorStatusAppointment(props.status) === colors.lightGray ? colors.disabledGray : getColorStatusAppointment(props.status)} />
                </Box>
                <Typography className={`ml-1`} sx={{ fontWeight: 500, flex: 1, height: '24px' }}>
                  {/* {t('REGISTER.SPACIAL_INFO')} : */}
                </Typography>
              </Box>
              <Typography className={`mx-1 text-ellipsis`}>{props.specialInfo || '-'}</Typography>
            </Box>
          </>
        ) : (
          <>
            <BoxTextCardAppointment heigth={height < 40}>
              <Typography
                className={'text-card-appointment m-0 overflow-hidden'}
                sx={{ whiteSpace: 'nowrap' }}
              >
                {`${props.branchCnNumber}  | ${props.patientFullName || ''}`} {minute <= 10 && props?.serviceText && <span> | {props.serviceText}</span> || <></>}
              </Typography>
              <Typography className={'text-card-appointment m-0 text-ellipsis'}>
                {`${renderPhone()}${props.rangeTimeText} ${t('TIME_UNIT')} `}
              </Typography>
            </BoxTextCardAppointment>
            {height > 72 &&
              <Typography className={'m-0 text-ellipsis'}>
                {minute >= 15 ? (
                  <Box className='d-flex align-items-start' sx={{ marginTop: '-2px' }}>
                    <Box className='d-flex align-items-center' sx={{ flex: 'none' }}>
                      <Box className='d-flex align-items-center justify-content-center' sx={{ flex: 'none', width: '20px', svg: { width: '16px' } }}>
                        <ThemeIcon.Treatment stroke={getColorStatusAppointment(props.status) === colors.lightGray ? colors.disabledGray : getColorStatusAppointment(props.status)} />
                      </Box>
                      <Typography className='ml-1' sx={{ fontWeight: 500, flex: 1, height: '24px' }}>
                        {/* {t('APPOINTMENT.FORM.TREATMENT_TITLE')} : */}
                      </Typography>
                    </Box>
                    <Typography className='mx-1 text-ellipsis'>{props.serviceText}</Typography>
                    <Typography className={'m-0'} sx={{ flex: 'none' }}>
                      {props.hasLab && checkPackage(PACKAGE_ADDONS.ADDON_LAB) ? <Tag>LAB</Tag> : <></>} {props.hasXray && checkPackage(PACKAGE_ADDONS.ADDON_X_RAY) ? <Tag>X-Ray</Tag> : <></>}
                    </Typography>
                  </Box>
                ) : (
                  <span className={'m-0 text-ellipsis'}>
                    <span className={'mx-1'}>|</span>
                    {props.serviceText} {props.hasLab && checkPackage(PACKAGE_ADDONS.ADDON_LAB) ? <Tag>LAB</Tag> : <></>} {props.hasXray && checkPackage(PACKAGE_ADDONS.ADDON_X_RAY) ? <Tag>X-Ray</Tag> : <></>}
                  </span>
                )}
              </Typography>
            }
          </>
        )}
      </Box>
    </CardAppointment>
  </div>
  )
}
