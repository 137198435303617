import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'

export interface AppointmentOperativeInterface {
  appointmentOperativeId: number
  appointmentId: number
  operativeTypeId: number
  operativeTypeName: string
  operativeId: number
  operativeName: string
  color: string
  createdBy: string
  createdAt: Date
}

export interface AppointmentInterface {
  appointmentId: number
  appointmentNumber: string
  branchId: number
  branchName?: string
  patientId: number
  patientFullname: string
  cnNumber: string
  branchCnNumber: string
  snCode?: string
  vnNumber: string
  phone: string
  date: string
  timeStart: string
  durationText: string
  durationMinute: number
  doctorId: number
  doctorFullname: string
  practice: string
  price: number
  hasLab: string
  hasXray: string
  remark: string
  remarkCancel: string
  statusAppointment: string
  status: string
  updatedBy: string
  updatedAt: string
  createdBy: string
  createdAt: string
  specialInfo: string
  appointmentOperatives?: AppointmentOperativeInterface[]
  isApplication?: "0" | "1",
  applicationDetail?: string
}

export interface SchedulerStatus {
  statusAppointment: 'CONFIRM' | 'CANCEL'
  remarkCancel?: string
}

export interface MessageTemplate {
  templateTextId: number
  message: string
}

export interface ValidateAppointmentInterface {
  date: string
  timeStart: string
  branchId: number
  doctorId: number
  durationMinute?: number
  appointmentId?: number | 0
}

export type StatusWaitAppointment = 'NORMAL' | 'CONFIRM' | 'PUT_OFF' | 'CAN_NOT_CONTACT' | 'NOT_YET' | 'ARRIVED' | 'PENDING_PAYMENT' | 'PAYMENT' | 'PENDING_SERVICE' | 'SERVICE' | 'DONE' | 'CANCEL' | 'WAITING_APPROVE' | 'CANCEL_APP'

const path = 'appointments'
const schedulerPath = 'scheduler'

export default class AppointmentApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get(`${path}`, { params: { ...props } })
  }

  static findAllHistory(props: FindAllInterface): Promise<any> {
    return this.api.get(`${path}/patient`, { params: { ...props } })
  }

  static create(payload: any) {
    return this.api.post(`${path}`, payload)
  }

  static findById(id: number): Promise<AppointmentInterface> {
    return this.api.get(`${path}/${id}`)
  }

  static update(id: number, body: AppointmentInterface) {
    return this.api.patch(`${path}/${id}`, body)
  }

  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`${path}/${id}/status`, { id, status })
  }

  static remove(id: number) {
    return this.api.delete(`${path}/${id}`)
  }

  static displayDay(props: any): Promise<any> {
    return this.api.get(`${path}/display-days`, { params: { ...props } })
  }

  static displayWeek(props: any): Promise<any> {
    return this.api.get(`${path}/display-weeks`, { params: { ...props } })
  }

  static displayMonth(props: any): Promise<any> {
    return this.api.get(`${path}/display-months`, { params: { ...props } })
  }

  static countAppointment(props: any): Promise<any> {
    return this.api.get(`${path}/count-appointments`, { params: { ...props } })
  }

  static printAppointment(appointmentId: number): Promise<any> {
    return this.api.get(`${path}/${appointmentId}/print`)
  }

  static loadScheduler(appointmentId: number): Promise<any> {
    return this.api.get(`${schedulerPath}/appointments/${appointmentId}`)
  }

  static updateStatusScheduler(appointmentId: number, payload: SchedulerStatus): Promise<any> {
    return this.api.patch(`${schedulerPath}/confirm-appointment/${appointmentId}`, payload)
  }

  static updateSpecialInfo(payload: any) {
    return this.api.patch(`${path}/special-info`, payload)
  }

  static newTemplateMessagePratice(payload: MessageTemplate[]): Promise<any> {
    return this.api.post(`${path}/tempate-text-pratice`, payload)
  }

  static newTemplateMessageRemark(payload: MessageTemplate[]): Promise<any> {
    return this.api.post(`${path}/tempate-text-remark`, payload)
  }

  static templateMessagePraticeBranch(): Promise<any> {
    return this.api.get(`${path}/template-text-pratice-branch`)
  }

  static templateMessagePraticeUser(): Promise<any> {
    return this.api.get(`${path}/template-text-pratice-user`)
  }

  static templateMessageRemarkBranch(): Promise<any> {
    return this.api.get(`${path}/template-text-remark-branch`)
  }

  static templateMessageRemarkUser(): Promise<any> {
    return this.api.get(`${path}/template-text-remark-user`)
  }

  static validateAppointment(params: ValidateAppointmentInterface): Promise<any> {
    return this.api.get(`${path}/validate`, { params: params })
  }

  static waitingApprove(params: { branchId: number, status: StatusWaitAppointment }): Promise<any> {
    return this.api.get(`${path}/waiting-approve`, { params: params })
  }

  static templateMessageAppBranch(): Promise<any> {
    return this.api.get(`${path}/template-text-app-branch`)
  }
  static templateMessageAppUser(): Promise<any> {
    return this.api.get(`${path}/template-text-app-user`)
  }
}

